import config from "@/config";

import { get, del, post, patch } from "@/requests"

export async function getCategoriesTypeData() {
    return get(`${config.BACK_IP}/categories_type`)
}
export async function getCategoryTypeData(id) {
    return get(`${config.BACK_IP}/category_type/${id}`)
}
export async function createNewTypeCategory(newTypeCategory) {
    return post(`${config.BACK_IP}/category_type`, newTypeCategory)
}
export async function updateTypeCategory(typeCategory, id) {
    return patch(`${config.BACK_IP}/category_type/${id}`, typeCategory)
}
export function deleteCategoryType(id) {
    return del(`${config.BACK_IP}/category_type/${id}`)
}