<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable
          :fields="fields"
          :items="categoriesType"
          :actions="actions"
          title="Tipos de categoría"
          labelItemPerPage="Tipos de categoría por página:"
          :addBtn="{
            enable: $store.state.isAdmin,
            text: 'Añadir tipo de categoría',
            event: 'addElement',
          }"
          @addElement="addCategoryType"
          @editInfo="editCategoryType"
          @showDeleteModal="handleModal"
          :noResults="'No se han encontrado tipos de categoría'"
        />

        <DeleteModal
          :modalContent="modalContent"
          @delete="deleteCategoryTypeData(categoryTypeId)"
          :showModal="deleteModal"
          @closeModal="closeModal"
        />
      </CCol>
    </CRow>
  </div>
</template>
    
<script>
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getCategoriesTypeData, deleteCategoryType } from "@/services/category_type";
import { generateField } from "@/utils/utils";
export default {
  name: "categoriesTypeTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      categoriesType: [],
      categoryType: {},
      categoryTypeId: 0,
      categoryTypeName: "",
      loading: true,
      deleteModal: false,
      fields: [
        generateField("name", "Nombre", { styleData: { textAlign: "left" } }),
        generateField("actions", "Acciones", { sorter: false, filter: false }),
      ],
      actions: [
        {
          key: "info",
          label: "Información",
          event: "editInfo",
          classButton: "btn btn btn-info btn-success btn-sm btn-pill",
        },
        {
          key: "delete",
          label: "Eliminar",
          isAdmin: !this.$store.state.isAdmin,
          event: "showDeleteModal",
          classButton: "btn btn-delete btn-sm btn-pill",
        },
      ],
      modalContent: {
        title: "Eliminar tipo de categoría",
        textBody: "",
        buttons: [
          {
            textButton: "Cerrar",
            color: "secondary",
            event: "closeModal",
          },
          {
            textButton: "Aceptar",
            color: "danger",
            event: "delete",
          },
        ],
      },
    };
  },
  created() {
    this.getCategoriesType();
  },
  methods: {
    /**
     * Función en la que se obtienen los tipos de categoría
     *
     * @returns {object[]} Los tipos de categoría obtenidos.
     */
     getCategoriesType() {
       getCategoriesTypeData()
        .catch((error) => {
          this.$store.state.errorAlert = {
            status: true,
            msg: error.toString(),
          };
          this.loading = false;
        })
        .then((result) => {
          this.categoriesType = result;
          this.categoriesType.forEach((categoryType) => {
            categoryType.id = categoryType.id;
            categoryType.name = categoryType.name;
          });
          this.loading = false;
        });
    },
    /**
     * Este método se llama cuando el usuario pulsa el botón Crear nuevo tipo de categoría y redirige a la vista con el formulario para poder crear una.
     */
    addCategoryType() {
      this.$router.push(`/type-categories/create/`);
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar el tipo de categoría.
     */
    handleModal(item) {
      this.categoryTypeId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar el tipo de categoría: <strong>"${item.name}</strong>"? Esta acción no se puede deshacer.`;
      this.deleteModal = true;
    },
    /**
     * Este método se llama cuando el usuario pulsa el botón Editar y redirige a la vista con el formulario para poder editar una.
     */
    editCategoryType(item) {
      this.$router.push(`/type-categories/info/${item.id}`);
    },
    /**
     * Este metodo eliminará la categoría, mandando por la URL el id
     *
     * @param {number} id - El id de la categoría a eliminar
     */
    deleteCategoryTypeData(id) {
        deleteCategoryType(id).then(() => {
        this.getCategoriesType();
      });
      this.deleteModal = false;
    },
    /**
     * Función para cerrar el Modal.
     */
    closeModal() {
      this.deleteModal = false;
    },
  },
};
</script>